import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'

import config from 'config'

const general = {
  id: 'general',
  title: 'General',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: HomeOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'jobs',
      title: 'Jobs',
      type: 'item',
      url: `${config.cms1Url}/service-jobs`,
      icon: FormatListBulletedOutlinedIcon,
      breadcrumbs: false,
      external: true,
      target: true,
    },
    {
      id: 'liveCapacity',
      title: 'Live Capacity',
      type: 'item',
      url: `${config.cms1Url}/liveCapacity`,
      icon: SpeedOutlinedIcon,
      breadcrumbs: false,
      external: true,
      target: true,
    },
  ],
}

export default general
